import { OctraNavigation } from '@core/types/octra-navigation.type';
import { environment } from '@environments/environment';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { ClientModules } from '@shared/clients/types/client-modules.enum';
import { ClientViewPrivilegesEnum } from '@shared/clients/types/client-privileges.enum';

const nav: OctraNavigation[] = [
  {
    icon: ['network_node'],
    label: 'Accounts',
    clientCond: 'withSelectedClient',
    route: (params) => APP_ROUTES.accountsV1.overview(params.currentClientSlug)
  },
  {
    icon: ['account_balance'],
    label: 'Governance',
    clientCond: 'withSelectedClient',
    items: [
      {
        label: 'Best practices',
        route: (params) => APP_ROUTES.governance.bestPractices(params.currentClientSlug),
        privileges: [
          ClientViewPrivilegesEnum.StatsView,
          ClientViewPrivilegesEnum.OpportunitiesView,
          ClientViewPrivilegesEnum.GovernanceView
        ]
      },
      {
        label: 'Naming conventions',
        route: (params) => APP_ROUTES.governance.namingConventions(params.currentClientSlug),
        requiredModule: [ClientModules.CUSTOM_NC, ClientModules.TEMPLATES_NC],
        privileges: [
          ClientViewPrivilegesEnum.StatsView,
          ClientViewPrivilegesEnum.OpportunitiesView,
          ClientViewPrivilegesEnum.GovernanceView
        ],
        clientsNotVisible: environment.production ? ['nestle'] : []
      },
      {
        label: 'Anomaly detection',
        route: (params) => APP_ROUTES.governance.anomalyDetection(params.currentClientSlug),
        requiredModule: ClientModules.ANOMALY_DETECTION
      },
      {
        label: 'QA',
        route: (params) => APP_ROUTES.governance.qaChecklists.list(params.currentClientSlug)
      }
    ]
  },
  {
    icon: ['settings', 'outlined'],
    label: 'Client settings',
    clientCond: 'withSelectedClient',
    route: (params) => APP_ROUTES.client.general(params.currentClientSlug)
  }
];

if (!environment.production) {
  nav.push({
    icon: ['code'],
    label: 'Dev',
    route: () => ['/dev/css-utils']
  });
}

export const sideNavNavigation: OctraNavigation[] = nav;

export const sideNavNavigationClientSettings: OctraNavigation[] = [
  {
    icon: ['settings', 'outlined'],
    label: 'Client settings',
    clientCond: 'withSelectedClient',
    items: [
      {
        label: 'General',
        route: (params) => APP_ROUTES.client.general(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.ClientView
      },
      {
        label: 'Business structure',
        route: (params) => APP_ROUTES.client.businessStructure(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.BSView
      },
      {
        label: 'Users',
        route: (params) => APP_ROUTES.client.users(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.UsersView
      }
    ]
  }
];

export const sideNavNavigationAccountsSettings: OctraNavigation[] = [
  {
    icon: ['network_node'],
    label: 'Accounts',
    clientCond: 'withSelectedClient'
  }
];

export const sideNavNavigationUserSettings: OctraNavigation[] = [
  {
    icon: ['settings', 'outlined'],
    label: 'User settings',
    clientVisibility: 'hidden',
    items: [
      {
        label: 'Preferred platforms',
        route: (params) => APP_ROUTES.profile.settings()
      }
    ]
  }
];

export const sideNavNavigationDev: OctraNavigation[] = [
  {
    icon: ['code'],
    label: 'Dev',
    clientVisibility: 'hidden',
    items: [
      {
        label: 'Css utils',
        route: () => ['/dev/css-utils']
      },
      {
        label: 'Loader',
        route: () => ['/dev/loader']
      },
      {
        label: 'OCTRA UI Components',
        route: () => ['/dev/octra-styling']
      },
      {
        label: 'NgxTable Styling',
        route: () => ['/dev/ngx-table-styling']
      },
      {
        label: 'NgxTable Directive',
        route: () => ['/dev/ngx-table-directive']
      },
      {
        label: 'NgxTable Footers',
        route: () => ['/dev/ngx-table-footers']
      },
      {
        label: 'NgxTable Selection',
        route: () => ['/dev/ngx-table-selection']
      },
      {
        label: 'NgxTable Column & Width',
        route: () => ['/dev/ngx-table-width']
      },
      {
        label: 'Meta Business Auth',
        route: () => ['/dev/meta-business-auth']
      }
    ]
  }
];

export const sideNavNavigationClientsList: OctraNavigation[] = [
  {
    icon: ['home', 'outlined'],
    label: 'Clients list',
    clientVisibility: 'hidden'
  }
];
